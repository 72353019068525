<template>
  <div class="salaire-update-page page-layout">
    <PageHeader title="Modifier Salaire" :has-back="true" @back="goBack" />

    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <!--Smic-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('smic') }">
              <label>SMIC *</label>
              <md-input
                v-model="form.smic"
                v-validate="'required|decimal'"
                name="smic"
                type="smic"
              />
              <span class="md-error" v-show="errors.has('smic')">{{
                errors.first("smic")
              }}</span>
            </md-field>
          </div>

          <!--coef1a19-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef1a19') }">
              <label>Coef 1 à 19 *</label>
              <md-input
                v-model="form.coef1a19"
                v-validate="'required|decimal'"
                name="coef1a19"
                type="coef1a19"
              />
              <span class="md-error" v-show="errors.has('coef1a19')">{{
                errors.first("coef1a19")
              }}</span>
            </md-field>
          </div>

          <!--coef20plus-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef20plus') }">
              <label>Coef 20 ou plus*</label>
              <md-input
                v-model="form.coef20plus"
                v-validate="'required|decimal'"
                name="coef20Plus"
                type="coef20Plus"
              />
              <span class="md-error" v-show="errors.has('coef20plus')">{{
                errors.first("coef20plus")
              }}</span>
            </md-field>
          </div>

          <!--taux charge-->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('taux_charge') }">
              <label>Taux charge*</label>
              <md-input
                v-model="form.taux_charge"
                v-validate="'decimal'"
                name="taux_charge"
                type="taux_charge"
              />
              <span class="md-error" v-show="errors.has('taux_charge')">{{
                errors.first("taux_charge")
              }}</span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="updateSalaire"
            >
              Valider
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
    <!-- <md-card class="md-layout-item md-size-75 md-small-size-85">
      <md-card-header>
        <div class="md-title">
          <md-icon>delete_forever</md-icon>Supprimer cet impôt sur les sociétés
        </div>
      </md-card-header>
      <md-card-actions>
        <md-button
          class="md-raised md-primary"
          @click.native="openConfirmDeleteModal"
        >Supprimer ce salaire
          <md-icon>delete_forever</md-icon>
        </md-button>
      </md-card-actions>
    </md-card> -->
    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="removeSalaire"
    />
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import confirmActionModal from "../../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        smic: "",
        coef20plus: "",
        coef1a19: "",
        taux_charge: "",
      },
      isConfirmDeleteModalOpen: false,
      confirmDeleteModalText: {
        header: "Suppression d'un salaire",
        body() {
          return "Vous êtes sur le point de supprimer le salaire";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    calculatorsModel
      .getSalaire(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("salaire with id" + to.params.id + ":");
            console.log(vm.form);
          }
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },

  methods: {
    goBack() {
      window.history.back();
    },
    updateSalaire() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .updateSalaire(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "Le salaire a bien été modifié",
              });
              this.$router.push("/admin/calculators/salaire/list");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    removeSalaire() {
      calculatorsModel
        .removeSalaire(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "Le salaire a suprimé",
          });
          this.$router.push("/admin/calculators/salaire/list");
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.salaire-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
